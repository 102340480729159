@import "../../assets/scss/app.scss";

.error-message{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f46a6a;
}
.css-1hb7zxy-IndicatorsContainer{
  cursor: pointer;
}
@import "../../assets/scss/app";

.switch {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 24px;
  margin-top: 2px;
  margin-bottom: 0;
  cursor: pointer;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: #cacaca;
  -webkit-transition: .4s;
  transition: .4s;
  z-index: 5;
}

input:checked + .slider {
  background-color: #000000;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  transform: translateX(30px);
  background-color: #faa31b;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  background-color:  #484848;
}

.slider.round:before {
  border-radius: 50%;
  background-color: #cacaca;
}
.text-ch {
  position: absolute;
  color: red;
  top: 50%;
  transform: translate(0, -50%);
}

.text-checked {
 left: 7px;
  color: #faa31b
}

.text-not-checked {
  color: #f3f3f4;
  right: 9px;
}
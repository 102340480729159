@import "../../assets/scss/app.scss";

body .send-email-card-body{
  width: 600px;
  .card-body {
    border-top: 4px solid #e7eaec;
    padding-left: 0;
    padding-right: 0;
  }
}
body .creation-job {
  &-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    border-bottom: 1px solid #e7eaec;
    padding: 0 25px 15px;
  }

  &-card {
    width: 600px;
    .card-body {
      border-top: 4px solid #e7eaec;
      padding-left: 0;
      padding-right: 0;
    }
    &-group {
      margin-bottom: 23px;
    }
  }
}

body .send-email-title{
  margin-bottom: 0;
}

body .send-email-button:focus {
  background: #000000;
}



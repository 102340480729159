@import "../../../assets/scss/app.scss";

.sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $black;
  height: 100vh;

  .user {
    &-img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-top: 29px;
      margin-left: 26px;
    }

    &-email {
      color: $white;
      padding-top: 15px;
      margin-left: 26px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 200px;
    }
    &-email-hover {
      color: $white;
      padding-top: 15px;
      margin-left: 26px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 200px;
      cursor: pointer;
    }
  }

  .navigation {
    padding-top: 48px;
    list-style: none;
    padding-left: 0;
    padding-right: 0;
    border: none;

    > li {
      font-weight: 600;
      width: 100%;
      position: relative;
      transition: background-color 0.2s ease-in-out;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 100%;
        background-color: $treePoppy;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
      }

      &:hover {
        background-color: rgba(137, 128, 122, 0.2);

        &::before {
          opacity: 1;
        }
      }

      > a {
        display: block;
        width: 100%;
        height: 100%;
        color: $treePoppy;

        span {
          display: block;
          padding: 18px 25px;
        }
      }
    }
  }
  .active {
    position: relative;
    background-color: rgba(137, 128, 122, 0.2);
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      background-color: $treePoppy;
      opacity: 1;
      transition: opacity 0.2s ease-in-out;
    }
  }
}

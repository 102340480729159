@import "../../assets/scss/app";

body .modal{
  padding-left: 220px;
}

body .modal-container{
  top: calc(50% - 200px);
  .modal-content{

    width: 407px;
    height: 245px;
    padding: 50px;
    border-radius: 4px;
    background-color: #fff;
    color: #676a6c;

    h3{
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    .modal-text{
      font-size: 13px;
      margin-bottom: 56px;
    }
    .buttons-container{
      display: flex;

      .close-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 140px;
        height: 34px;
        margin-left: 25px;
        border-radius: 2px;
        color: #faa31b;
        border: none;
        background-color: #000;

      }

      .cancel-button{
        width: 134px;
        height: 34px;
        border-radius: 2px;
        background: #ffffff;
        color: #ed5565;
        border: solid 1px #ed5565;
      }
    }

    .close-modal-button{
      position: absolute;
      right: 15px;
      top: 15px;
      border: none;
      padding: 0;
      background-color: transparent;
      width: 11px;
      height: 11px;
    }

    .close-modal-icon{
      width: 11px;
      height: 11px;
      fill: grey;
      vertical-align: top;
    }

  }

}
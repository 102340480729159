@import "../../../assets/scss/app.scss";

.header {
  padding: 15px 25px 15px 20px;
  display: flex;
  justify-content: space-between;
  .title {
    color: $treePoppy;
    font-family: Rockwell;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 30px;
  }

  .logout {
    display: flex;
    align-items: center;
    > span {
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      color: $nevada;
      margin-left: 10px;
    }
  }
}

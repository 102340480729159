@import "../../assets/scss/app";

body .employee-edit-close-button{
  width: 122px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px #faa31b;
  background-color: #fff;
  color: #faa31b;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
}
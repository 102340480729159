@import "../../assets/scss/app";

.creation-certificate-body-card{
  form{
    padding: 20px 24px 10px;

    .certificate-fields-container{
      display: flex;
      font-size: 13px;
      font-weight: bold;

      .certificate-name-header{
        display: flex;
        align-items: center;
        width: 69%;
        height: 36px;
        border: solid 1px #e5e6e7;
        padding-left: 16px;
      }
      .certificate-required-header{
        width: 31%;
        border: solid 1px #e5e6e7;
        border-left: none;
        padding: 0 16px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .certificate-name-field{
        display: flex;
        align-items: center;
        width: 69%;
        min-height: 64px;
        border: solid 1px #e5e6e7;
        padding: 9px 20px 9px 16px;
        border-top: none;

        .certificate-field{
          width: 100%;
          margin-bottom: 20px;
          .auth-form-label{
            font-weight: bold;
          }
          .invalid-feedback{
            font-weight: 400;
          }
          .certificate-field-input{
            border-radius: 0;
            background-image: none;
            &::placeholder{
              color: #999999;
            }
          }
        }

        .switch{
          margin-right: 8px;
        }
      }
      .certificate-required-field{
        width: 31%;
        border: solid 1px #e5e6e7;
        min-height: 64px;
        border-left: none;
        padding: 9px 20px 9px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: none;


        .container-check{
          width: 0;
          display: flex;
          justify-content: center;
          margin: 0;

        }
      }
    }
  }
  .button-container {
    display: flex;
    justify-content: end;
    margin-right: 0;
    margin-left: auto;
    padding-top: 24px;
  }

  .certificate-save-button{
    width: 122px;
    height: 34px;
    background-color: #000000;
    border-radius: 2px;
    color: #faa31b;
    display: block;
    padding: 0;
    font-size: 14px;
    border: none;
    &:hover, &:focus {
      background-color: #000000;
    }
    &:disabled{
      opacity: 0.5;
    }
  }

  .close-button{
    width: 122px;
    height: 34px;
    border-radius: 2px;
    border: solid 1px #ed5565;
    background-color: #fff;
    color: #ed5565;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
  }
}
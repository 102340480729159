@import "../../assets/scss/app";


actions-container {
  display: flex;
}
.actions-control {
  background: transparent;
  border: none;
  padding: 0;
}

.actions-edit {
  margin-right: 37px;
}
@import "../../assets/scss/app.scss";

.breadcrumbs {
  padding: 30px 20px 25px;
  background-color: $white;
  border: 1px solid #e7eaec;
  margin-bottom: 25px;

  &-wr {
    li {
      a {
        color: #676a6c;
      }

      &:not(:first-child) {
        padding-left: 5px !important;
      }
      &:last-child {
        > a {
          font-weight: 600;
        }
      }
    }
  }

  .title {
    color: #999999;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 33px;
    padding-bottom: 3px;
  }
}

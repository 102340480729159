@import "../../assets/scss/app";

.Pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;

  ul {
    padding: 0;
    display: flex;
    height: 28px;
    margin-right: 16px;
    border-radius: 1px;
    border: solid 1px #ddd;

    li {
      list-style-type: none;
      margin: 0;
      height: 100%;
      text-align: center;
      a {
        display: block;
        min-width: 30px;
        line-height: 28px;
      }

      &:first-child  {
        width: 78px;
      }

      &:last-child {
        width: 50px;
      }

      &:not(:last-child) {
        border-right: solid 1px #ddd;
      }


      &.selected {
        width: auto;
        background-color: #f4f4f4;
        ;

        a {
          color: #676a6c;
        }
      }

      &.next,
      &.break,
      &.previous {
        a {
          color: #676a6c;
          font-size: 13px;
          font-weight: 400;;
          text-align: center;
          width: 100%;
        }
      }
    }

    a {
      color: #676a6c;
      font-size: 13px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      outline: none;
    }
  }
}
//
// Google font - Open Sans
//
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap");

//
// Rockwell
//
@font-face {
  font-family: "Rockwell";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../../../fonts/ROCK.TTF');
}

//
// Rockwell Blod
//
@font-face {
  font-family: "Rockwell";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../../../fonts/Rockwell-Bold.ttf');
}

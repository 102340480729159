@import '../../assets/scss/app.scss';

.login {
  &-container {
    height: 100vh;
    display: flex;
    align-items: center;
  }
  &-wr {
    width: 263px;
    margin: 0 auto;
  }

  &-bth {
    height: 34px;
    border: 0;
    outline: none;
    width: 100%;
    background-color: $black;
    color: $treePoppy;
    border-radius: 3px;
    font-size: 14px;
    &:disabled{
      opacity: 0.6;
    }
  }

  &-forgot {
    padding-top: 15px;
    display: block;
    text-align: center;
    color: $treePoppy;  
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    width: fit-content;
    margin: 0 auto;

    &:hover {
      color: $treePoppy;  
    }
  }

  &-head {
    text-align: center;
    padding-bottom: 25px;
    .title {
      display: block;
      color: $treePoppy;
      font-family: Rockwell, sans-serif;
      font-size: 50px;
      font-weight: 700;
      letter-spacing: 0;
      line-height: 30px;
      padding-bottom: 30px;
    }

    .subtitle {
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
      padding-bottom: 10px;
    }

    .text {
      font-size: 13px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
}

  body .login-container {
  .form-control {
    background-image: none;
  }
}

@import "../../assets/scss/app.scss";

.certificates-list-create-link {
  background-color: #000000;
  width: 180px;
  height: 34px;
  border-radius: 2px;
  font-weight: normal ;
  padding: 7px 33px 8px;
  color: #faa31b ;
  display: block;
  font-size: 14px;
  &:hover, &:focus {
    color: #faa31b ;
  }
}

.certificates-list-details-link{
  font-size: 12px;
  font-weight: normal;
  color: #faa31b;

  text-decoration: underline !important;
  &:hover, &:focus {
    color:  #faa31b;
  }
}


.certificates-empty-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 200px;

  .certificates-empty-content{
    font-size: 13px;
    font-weight: bold;
    color: #676a6c;
  }
}

.table-bordered {
  margin-bottom: 0px;
}

body .certificates-list-forms {
  &-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    border-bottom: 1px solid #e7eaec;
    padding: 0 25px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 82px;
    margin-bottom: 24px;

  }

  &-card {
    min-height: 588px;
    .card-body {
      border-top: 4px solid #e7eaec;
      padding-left: 0;
      padding-right: 0;
    }

  }

  &-tabel {
    padding: 0 15px;
    div[data-test="datatable-search"] {
      justify-content: flex-start ;
      order: -1;
      margin-top: 15px;
      margin-bottom: 20px;

      input {
        width: 222px;
        height: 34px;
      }
    }

    thead th {
      font-weight: 700 ;
      border-color: #E5E6E7 ;
      color: $nevada;
      padding: 0 ;
      height: 36px;
      vertical-align: middle;

      &:first-child {
        padding-left: 21px ;
      }
      &:nth-child(2) {
        padding-left: 24px;
      }
      &:nth-child(3) {
        padding-left: 1rem ;
      }
    }

    td {
      border-color: #E5E6E7 ;
      height: 36px;
      color: $nevada;
      vertical-align: middle;
      padding: 0 0 0 24px;

      &:first-child {
        width: 29%;
      }
      &:nth-child(2) {
        width: 55%;
      }
      &:nth-child(3) {
        width: 16%;
      }
    }

    .page-item {
      &:hover {
        > a {
          color: $nevada;
        }
      }
    }

    .disabled.page-item  {
      > a {
        border: 1px solid #dddddd ;
        color: $nevada;
      }
    }

    .active.page-item {
      > a {
        border: 1px solid #dddddd ;
        background-color: #f4f4f4 ;
        color: $nevada;
      }
    }

    &-view {
      border: none;
      padding: 0;
      outline: none;
      display: flex;
      background: none;
      margin-right: 20px;
      color: $treePoppy;
      text-decoration: underline;
    }

    &-bth {
      border: none;
      padding: 0;
      outline: none;
      display: flex;
      width: 79px;
      height: 22px;
      background-color: rgba(137, 128, 122, 0.2);

      > span {
        display: block;
        margin: auto;
        color: $black;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 17px;
      }
    }
  }
  .table th, .table td {
    vertical-align: center ;
  }
}

.download-container {
  display: flex;
  align-items: center;
}
div[data-test="datatable-search"] {
  .form-control {
    padding-left: 12px;
    padding-right: 12px;
    width: 221px;
    font-size: 13px;
    border-color: #e5e6e7;
    color: #333333;
    border-radius: 0;

    &::placeholder {
      color: #999999;
    }
    &:focus {
      color: #333333;
    }
  }
}
.dataTable {
  thead {
    > tr > th:active,
    > tr > td:active {
      outline: none;
    }
    .sorting,
    .sorting_asc,
    .sorting_desc,
    .sorting_asc_disabled,
    .sorting_desc_disabled {
      cursor: pointer;
      position: relative;
    }
    .sorting:before,
    .sorting:after,
    .sorting_asc:before,
    .sorting_asc:after,
    .sorting_desc:before,
    .sorting_desc:after,
    .sorting_asc_disabled:before,
    .sorting_asc_disabled:after,
    .sorting_desc_disabled:before,
    .sorting_desc_disabled:after {
      position: absolute;

      display: block;
      opacity: 0.7;
    }
    .sorting:before,
    .sorting_asc:before,
    .sorting_desc:before,
    .sorting_asc_disabled:before,
    .sorting_desc_disabled:before {
      top: 13px;
      right: 12px;
      content: "\25B2";
      font-size: 8px;
    }

    .sorting,
    .sorting_desc,
    .sorting_asc {
      &:last-child {
        &::before {
          display: none;
        }
        &::after {
          display: none;
        }
      }
    }
    .sorting:after,
    .sorting_asc:after,
    .sorting_desc:after,
    .sorting_asc_disabled:after,
    thead .sorting_desc_disabled:after {
      font-size: 8px;
      right: 12px;
      content: "\25BC";
      bottom: 11px;
      font-stretch: expanded;
    }
    & .sorting_asc:before,
    & .sorting_desc:after {
      opacity: 1;
      color: $treePoppy;
    }
    & .sorting_asc_disabled:before,
    & .sorting_desc_disabled:after {
      opacity: 0;
    }
  }
}


.certificates-list-forms-card div[data-test="datatable-search"], .certificates-list-forms-card div[data-test="datatable-search"] {
  display: none !important;
}


@import "../../assets/scss/app";

///*  CUSTOM CHECKBOX */

/* The container */
.container-check {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  margin-right: 48px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
//
///* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
//
///* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  height: 16px;
  width: 16px;
  padding: 5px 3px 4px;
  border-radius: 4px;
  border: 1px solid #000000;
  background-color: #fff;
}
//
///* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
  background-color: #ccc;
}
//
///* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  background-color: #000000;
}
//
///* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
//
///* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

// Style the disabled checkmark
.container-check input:disabled ~ .checkmark {
  border: 1px solid #777777;
  background-color: #eee;
  cursor: auto;
}
//
///* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 4px;
  top: 0;
  width: 7px;
  height: 10px;
  border: solid  #faa31b;
  border-width: 0 2px 2px 0;
  transform: rotate(40deg);
}


@import "../../assets/scss/app";

.job-list-navbar {
  width: 100%;
  height: 63px;
  background-color: #ffffff;
  margin-bottom: 24px;
  padding-left: 24px;
  display: flex;

  &-link {
    font-size: 14px;
    font-weight: 600;
    color: #676a6c;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 140px;
    border-bottom: 2px solid #676a6c;

    &:hover, &:focus {
      color: #676a6c;
    }
  }

  &-link-active{
    color: #faa31b;
    border-bottom: 2px solid #faa31b;

    &:hover, &:focus {
      color: #faa31b;
    }
  }
}
@import "../../assets/scss/app";


.button-container {
  display: flex;
  justify-content: end;
  margin-right: 0;
  margin-left: auto;
  padding-top: 24px;
}

.submit-button{
  width: 122px;
  height: 34px;
  background-color: #000000;
  border-radius: 2px;
  color: #faa31b;
  display: block;
  padding: 0;
  font-size: 14px;
  border: none;
  &:hover, &:focus {
    background-color: #000000;
  }
  &:disabled{
    opacity: 0.4;
  }
}

.cancel-button{
  width: 122px;
  height: 34px;
  border-radius: 2px;
  border: solid 1px #ed5565;
  background-color: #fff;
  color: #ed5565;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
}
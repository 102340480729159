@import "../../assets/scss/app.scss";

.not-found {
  padding-top: 185px;
  width: 382px;
  margin: 0 auto;
  text-align: center;

  &-title {
    font-weight: 400;
    font-size: 122px;
    color: $black;
    padding-bottom: 5px;
  }

  &-subtitle {
    font-size: 14px;
  }

  &-text {
    font-size: 12px;
    padding: 30px 0 64px;
  }

  &-form {
    width: fit-content;
    display: flex;
    margin: 0 auto;

    input {
      width: 185px;
      height: 35px;
      margin-right: 2px;
      border: 1px solid #E5E6E7;;
    }

    
    button {
      color: $white;
      background-color: $treePoppy;
      height: 35px;
      width: 70px;
    }
  }
}

@import '../../assets/scss/app.scss';

body .settings {
  padding: 30px 25px 0;

  &-modal {
    .modal-content {
      width: 407px;
      height: 202px;
      padding: 15px 15px 0 50px;

      .modal-text {
        padding-top: 15px;
        color: $nevada;
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        padding-bottom: 49px;
      }
      .close {
        width: fit-content;
        margin-left: auto;
      }
      .bth-ok {
        outline: none;
        background: none;
        border: 1px solid $treePoppy;
        display: flex;
        width: 300px;
        height: 35px;
        > span {
          margin: auto;
          color: $black;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 17px;
        }
      }
    }
  }

  &-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    border-bottom: 1px solid #e7eaec;
    padding: 0 25px 15px;
  }

  &-card {
    .card-body {
      border-top: 4px solid #e7eaec;
      padding-left: 0;
      padding-right: 0;

      .form-control{
        background-image: none;
      }
    }
  }

  &-bth {
    display: block;
    height: 34px;
    border: 0;
    outline: none;
    width: 263px;
    margin-left: auto;
    background-color: $black;
    color: $treePoppy;
    border-radius: 3px;
    margin-bottom: 39px;
    margin-top: 33px;
    font-size: 14px;
  }

  input {
    color: #333333;
    border-radius: 0;

    &::placeholder {
      color: #999999;
    } 
    &:focus {
      color: #333333;
      border-color: $treePoppy;
    }

    &:-webkit-autofill::first-line {
      color: #333333;
    }
  }
}

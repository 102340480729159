@import "../../assets/scss/app.scss";

.controls-container{
  display: flex;
  align-items: center;
  height: 78px;
  padding-left: 24px;
}

.search-container {
  margin-right: 48px;
  align-items: center;
  position: relative;

  input{
    width: 221px;
    height: 34px;
    padding: 0 0px 0 12px;
    border: solid 1px #e5e6e7;
    background-color: #fff;
    &:focus{
      outline: none;
    }
    &::placeholder{
      color: #999999;
    }
  }
  .search-cv-submit{
    width: 117px;
    height: 34px;
    background-color: #000;
    border: none;
    color: #faa31b;
  }
  .search-cv-close-icon{
    fill: grey;
    vertical-align: top;
    height: 14px;
    width: 14px;
  }
  .search-cv-reset{
    position: absolute;
    right: 124px;
    top: 10px;
    border: none;
    padding: 0;
    background-color: transparent;
    width: 14px;
    height: 14px;
  }
}

.check-box-container{
  display: flex;
  align-items: center;
  &-icon{
    margin-right: 8px;
  }
  &-label{
    margin-right: 24px;
    font-size: 14px;
    font-weight: 600;
    color: #676a6c;
  }

  /*  CUSTOM CHECKBOX */

  /* The container */
  .container-check {
    //display: block;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 24px;
    margin-bottom: 0px;
    margin-right: 48px;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    color: #676a6c;
    user-select: none;
  }
  //
  ///* Hide the browser's default checkbox */
  .container-check input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  //
  ///* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    height: 16px;
    width: 16px;
    padding: 5px 3px 4px;
    border-radius: 4px;
    border: 1px solid #000000;
    background-color: #fff;
  }
  //
  ///* On mouse-over, add a grey background color */
  .container-check:hover input ~ .checkmark {
    background-color: #ccc;
  }
  //
  ///* When the checkbox is checked, add a blue background */
  .container-check input:checked ~ .checkmark {
    background-color: #000000;
  }
  //
  ///* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  //
  ///* Show the checkmark when checked */
  .container-check input:checked ~ .checkmark:after {
    display: block;
  }
  //
  ///* Style the checkmark/indicator */
  .container-check .checkmark:after {
    left: 4px;
    top: 0px;
    width: 7px;
    height: 10px;
    border: solid  #faa31b;
    border-width: 0 2px 2px 0;
    transform: rotate(40deg);
  }
}


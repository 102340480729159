@import "../../assets/scss/app.scss";

.search-cv-button{
  background-color: transparent;
  margin-right: 16px;
  border-radius: 2px;
  height: 22px;
  border: solid 1px #faa31b;
  font-size: 12px;

  &-cv{
    width: 50px;
  }
  &-certification{
    width: 78px;
  }
  &-employment{
    width: 85px;
  }
  &-decline{
    width: 45px;
    padding-left: 0;
    padding-right: 0;
    border: none;
    color: #fa2727;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }
}
@import "../../assets/scss/app.scss";

body .creation-job {
  &-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    border-bottom: 1px solid #e7eaec;
    padding: 0 25px 15px;
  }

  &-card {
    width: 600px;
    .card-body {
      border-top: 4px solid #e7eaec;
      padding-left: 0;
      padding-right: 0;
    }
    &-group {
      margin-bottom: 23px;
    }
  }
  &-form {
    padding: 24px 24px 25px;

    &-group {
      margin-bottom: 39px;
      .auth-form-label {
        color: #676A6C !important;
      }
      .invalid-feedback {
        &:first-letter {text-transform: uppercase}
      }
    }
    label {
      margin-bottom: 0px;
    }

    &-fieldset {
      display: flex;
      margin-bottom: 24px;
      .form-check {
        margin-right: 48px;
        font-size: 14px;

        label {
          margin-bottom: 0;
        }

      }
    }
    &-button {
      background-color: #000000;
      width: 189px;
      height: 34px;
      border-radius: 2px;
      color: #faa31b !important;
      display: block;
      margin-right: 0;
      margin-left: auto;
      padding: 0;
      font-size: 14px;
      &:disabled, &-disabled {
        opacity: 0.4;
        transition: none;
      }
      &:hover, &:focus {
        background-color: #000000;
      }
    }
    .form-control {
      border: solid 1px #eee;
      border-radius: 0;
      color: #333333;
      &:focus {
        border: solid 1px #faa31b;
      }
      &::placeholder{
        color: #999;
      }
    }
    .is-invalid {
      background-image: none;
    }
    .form-group {
      margin-bottom: 23px;
    }
    .button-container {
      display: flex;
      justify-content: end;
    }
    .close-button{
      width: 122px;
      height: 34px;
      border-radius: 2px;
      border: solid 1px #ed5565;
      background-color: #fff;
      color: #ed5565;
      margin-right: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: normal;
    }
    .edit-button{
      margin-left: 0;
    }

  }
}
body .creation-job-form-fieldset{
  &:first-of-type {
    display: flex;
    align-items: flex-start;
    margin-bottom: 5px;
  }


  .custom-radio-label {
    font-size: 14px;
    margin-right: 48px;
    line-height: 32px;
    cursor: pointer;
  }

  .custom-radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 16px;
    height: 16px;

    border: solid 2px #000;
    transition: 0.2s all linear;
    outline: none;
    margin-right: 5px;

    position: relative;
    top: 4px;
    cursor: pointer;
  }

  .custom-radio:checked {
    border: 4px solid black;
    background-color: #faa31b;
  }

  button,
  legend {
    color: white;
    background-color: black;
    padding: 5px 10px;
    border-radius: 0;
    border: 0;
    font-size: 14px;
  }

  button:hover,
  button:focus {
    color: #999;
  }

  button:active {
    background-color: white;
    color: black;
    outline: 1px solid black;
  }
}


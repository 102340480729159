@import "../../assets/scss/app.scss";

body .creation-employer {
  &-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    border-bottom: 1px solid #e7eaec;
    padding: 0 25px 15px;
  }

  &-card {
    width: 600px;
    min-height: 600px;
    .card-body {
      border-top: 4px solid #e7eaec;
      padding-left: 0;
      padding-right: 0;
    }
    &-group {
      margin-bottom: 23px;
    }
  }
  &-form {
    padding: 24px 24px 25px;

    &-group {
      margin-bottom: 39px;
      .auth-form-label {
        color: #676A6C !important;
      }
      .invalid-feedback {
        &:first-letter {text-transform: uppercase}
      }
    }
    label {
      margin-bottom: 0px;
    }

    &-fieldset {
      display: flex;
      margin-bottom: 24px;
      .form-check {
        margin-right: 48px;
        font-size: 14px;

        label {
          margin-bottom: 0;
        }

      }
    }
    &-button {
      background-color: #000000;
      width: 189px;
      height: 34px;
      border-radius: 2px;
      color: #faa31b !important;
      display: block;
      margin-right: 0;
      margin-left: auto;
      padding: 0;
      font-size: 14px;
      border: none;
      &:disabled, &-disabled {
        background-color: rgba(0, 0, 0, 0.4);
      }

    }
    .form-control {
      border: solid 1px #eee;
      border-radius: 0;
      color: #333333;
      &:focus {
        border: solid 1px #faa31b;
      }

    }
    .is-invalid {
      background-image: none;
    }
    .form-group {
      margin-bottom: 23px;
    }

  }
}


body .creation-employer-form {
  .employer-form {
    margin-bottom: 64px;
  }
}

.employer-button-container{
  display: flex;
  justify-content: right;
  .cancel-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    width: 122px;
    height: 34px;
    border-radius: 2px;
    border: solid 1px #faa31b;
    background-color: #fff;
    &:hover {
      background-color: #fff;
      border: solid 1px #faa31b;
    }
  }
  .submit-button{
    margin-left: 0;
    background-color: #000;
    &:hover {
      background-color: #000;
    }
    &:disabled:hover {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}
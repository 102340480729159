@import "../../assets/scss/app.scss";

.cv-create-link {
  background-color: #000000;
  width: 189px;
  height: 34px;
  border-radius: 2px;
  font-weight: normal ;
  padding: 7px 72px ;
  color: #faa31b ;
  display: block;
  font-size: 14px;
  &:hover {
    color: #faa31b ;
  }
}

.cv-empty-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;

  .cv-empty-content{
    font-size: 13px;
    font-weight: bold;
    color: #676a6c;
  }
}

.table-bordered {
  margin-bottom: 0px;
}

body .cv-forms {
  &-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    border-bottom: 1px solid #e7eaec;
    padding: 0 25px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 82px;
    margin-bottom: 0;

  }

  &-email-link {
    color: #676a6c;

    &:hover, &:focus {
      color: #484848;
    }
  }

  &-card {
    min-height: 588px;

    .card-body {
      border-top: 4px solid #e7eaec;
      padding-left: 0;
      padding-right: 0;
    }

  }
}

.cv-forms-card div[data-test="datatable-search"], .cv-forms-card div[data-test="datatable-search"] {
  display: none !important;
}


body .cv-table{
  thead{
    th{
      padding: 0 0 0 8px;
    }
  }
  tbody{
    td{
      padding: 0 8px 0 8px;
    }
  }
}


@import "../../assets/scss/app.scss";

.table-container{
  padding: 0 15px;
}

.table-scroll-container{
  overflow-x: auto;
}

body .custom-table {
  padding: 0 15px;

  thead {
    th {
      font-weight: 700 ;
      border-color: #E5E6E7 ;
      color: $nevada;
      height: 36px;
      vertical-align: middle;
      padding: 0 0 0 16px;
    }
    > tr > th:active,
    > tr > td:active {
      outline: none;
    }
    .sortable,{
      cursor: pointer;
      position: relative;
    }
    .sortable:before,
    .sortable:after, {
      position: absolute;

      display: block;
      opacity: 0.7;
    }
    .sortable:before,   {
      top: calc(50% - 12px);
      right: 10px;
      content: "\25B2";
      font-size: 8px;
    }

    .sortable:after{
      font-size: 8px;
      right: 10px;
      content: "\25BC";
      bottom: calc(50% - 12px);
      font-stretch: expanded;
    }

    .asc:before{
      color: #faa31b;
    }

    .desc:after{
      color: #faa31b;
    }
  }

  tbody{
    tr:nth-child(2n - 1){
      background-color: #f8f9fa;

    }
    td {
      border-color: #E5E6E7 ;
      height: 36px;
      color: $nevada;
      vertical-align: middle;
      padding: 0 0 0 16px;
    }
  }
}

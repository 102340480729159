.status {
  border-radius: 10px;
  text-align: center;

  &-pending {
    max-width: 65px;
    color: #967A26;
    background-color: #FFF8C3;
  }

  &-hired {
    max-width: 50px;
    color: #50A034;
    background-color: #CFF7C2;
  }

  &-didnt {
    max-width: 55px;
    color: #BF5854;
    background-color: #F5D6D6;
  }


}
body .rdw-editor-toolbar,
{
  margin-bottom: 0;
  border: solid 1px #eee !important;
  border-bottom: none !important;
  background-color: white !important;
}

body .rdw-option-wrapper {
  background-color: white !important;
  border: none;
}


div[title="Strikethrough"],
div[title="Monospace"],
div[title="Superscript"],
div[title="Subscript"],
div[title="Block Type"],
div[title="Font Size"],
div[title="Indent"],
div[title="Outdent"],
div[title="Strikethrough"],
.rdw-text-align-wrapper,
.rdw-dropdown-selectedtext,
.rdw-fontsize-dropdown,
.rdw-block-dropdown,
.rdw-text-align-wrapper,
.rdw-fontfamily-wrapper,
.rdw-colorpicker-wrapper,
.rdw-link-wrapper,
.rdw-embedded-wrapper,
.rdw-emoji-wrapper,
.rdw-image-wrapper,
.rdw-remove-wrapper,
.rdw-history-wrapper{
  display: none;
}


div[aria-selected="true"] {
  background-color:  #ededed !important;
}
.public-DraftEditorPlaceholder-inner,
.public-DraftEditorPlaceholder-root{
  color:  #999999 !important;
}


body .rdw-editor-main {
  height: 200px;
  z-index: 1;
  border: solid 1px #eee !important;
}

body .public-DraftStyleDefault-block{
  margin: 8px 0;
  span{
    line-height: 1.2;
  }
}

body .rdw-editor-main::-webkit-scrollbar {
  width: 3px;
  border: none;
}

body .rdw-editor-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body .rdw-editor-main::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.DraftEditor-root {
  height: initial;
}
.DraftEditor-editorContainer{
  z-index: 0;
}
.description-counter{
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #777;
  padding-left: 5px;
  display: block;
  position: absolute;
  right: 26px;
  text-align: right;
}
.cv-email-link{
  color: #676a6c;
  &:hover, &:focus {
    color: #676a6c;
  }
}

.employee-field-item {
  color: #676a6c;
  text-decoration: none;

  &:hover, &:focus {
    color: #676a6c;
  }
}
@import "../../assets/scss/app.scss";


body .employer-details{
  .form-control{
    color: #999999;

    &:hover, &:focus, &:active{
      outline: none;
      border: solid 1px #eee;
    }
  }
  .input-container{
    margin-bottom: 23px;
  }
  .employer-details-delete-button{
    color: #fa2727 !important;
    width: 122px;
    height: 34px;
    border-radius: 2px;
    border: solid 1px #fa2727;
    background-color: #fff;
    font-size: 14px;
    box-shadow: none;
  }

  .employer-details-update-button{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    width: 189px;
    height: 34px;
    border-radius: 2px;
    color: #faa31b;

    margin-right: 0;
    margin-left: auto;
    padding: 0;
    font-size: 14px;
    border: none;
    &:disabled, &-disabled {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}
.employee-info-card {
  min-height: 588px;

  &-body {
    border-top: 4px solid #e7eaec;
    padding-left: 24px;
    padding-right: 24px;
  }

  &-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    border-bottom: 1px solid #e7eaec;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 67px;
    margin-bottom: 24px;
  }

  &-content {
    width: 100%;
  }

  &-avatar {
    max-width: 336px;
    margin-right: 41px;
  }

  &-content-item {
    display: flex;
  }

  &-content-description {
    width: 110px;
    margin-right: 18px;
    padding-bottom: 13px;
    font-weight: 800;
  }

  &-documents-title {
    margin: 41px 0 20px 0;
    font-weight: 800;
  }

  &-files {
    margin-right: 48px;
  }

  &-files-btn {
    background: transparent;
    margin: 12px 16px 0 0;
    color: #faa31b;
    border: 1px solid #faa31b;
    padding: 8px 17px;
    border-radius: 2px;
  }

  &-files-title {
    font-weight: 800;
  }
}


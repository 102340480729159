@import "../../assets/scss/app.scss";

body .handbooks{
  &-card-title {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    border-bottom: 1px solid #e7eaec;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 67px;
    margin-bottom: 24px;
  }

  &-card{
    min-height: 588px;
    &-body {
      border-top: 4px solid #e7eaec;
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  &-upload{
    margin-bottom: 24px;
    &-icon{
      margin-bottom: 9px;
    }
    &-label-major{
      font-size: 13px;
      font-weight: bold;
      margin-bottom: 9px;
    }

  &-container{
    min-height: 211px;
    border: dashed 2px #faa31b;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #676a6c;
    margin-bottom: 12px;
    cursor: pointer;

    &-disabled{
      border: dashed 2px #999999;
      opacity: 0.5;
      cursor: auto;
    }


  }
  }

  &-item-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px 8px 12px;

    height: 64px;
    border-radius: 4px;
    border: solid 1px #dbdbdb;
  }

  &-item{
    display: flex;
    align-items: center;

    &-icon{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      margin: 0 24px 0 0;
      border-radius: 4px;
      background-color: #000;
      color: #faa31b;
    }

    &-info-name{
      font-weight: bold;
      color: #676a6c;
    }
    &-delete{
      cursor: pointer;
    }
  }
}

body .handbook-empty-container{
  display: flex;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 42px;
  font-size: 13px;
  font-weight: bold;
  color: #676a6c;
}



@import "../../assets/scss/app";

body .certificate-body{
  padding: 0;

  .certificate-details-title-container{
    height: 89px;
    border-bottom: 1px solid #e7eaec;
    padding: 24px;
    display: flex;
    justify-content: space-between;

    .certificate-details-title{
      font-size: 14px;
      font-weight: 600;
      color: #676a6c;
    }
  }

  .certificate-asterisks{
    color: red;
  }

  .button-container {
    display: flex;
    justify-content: end;
    margin-right: 0;
    margin-left: auto;
  }

  .certificate-edit-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 122px;
    height: 34px;
    background-color: #000000;
    border-radius: 2px;
    color: #faa31b;
    //display: block;
    margin-right: 0;
    margin-left: auto;
    padding: 0;
    font-size: 14px;
    border: none;
    &:hover, &:focus {
      background-color: #000000;
    }
  }

  .close-button{
    width: 122px;
    height: 34px;
    border-radius: 2px;
    border: solid 1px #ed5565;
    background-color: #fff;
    color: #ed5565;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: normal;
  }
}

body .certificate-container{
  min-height: 89px;
  padding: 21px 24px 24px;
  display: flex;
  flex-wrap: wrap;

  .certificate-detail-empty-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .certificate-field{
    width: 33%;
    margin-bottom: 24px;
    &-label{
      font-size: 13px;
      font-weight: bold;
      color: #676a6c;
      margin-left: 12px;
    }
  }

}



